function App() {
  return (
    <section className="bg-gray-50">
  <div className="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center">
    <div className="mx-auto max-w-4xl text-center">
      <h1 className="text-3xl font-bold sm:text-5xl">
      2025
        <strong className="font-extrabold text-green-700 sm:block"> CASVAL Conference </strong>
      </h1>

      <p className="mt-4 sm:text-xl/relaxed">
        CASVAL Conference 2025'te daha iyinin peşinde üretmeye ve paylaşmaya geliyor.
      </p>

      <div className="mt-8 flex flex-wrap justify-center gap-4">
        <a
          className="block w-full rounded bg-green-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-green-700 focus:outline-none focus:ring active:bg-green-500 sm:w-auto"
          href="https://casval.org"
        >
          casval.org
        </a>

        <a
          className="block w-full rounded px-12 py-3 text-sm font-medium text-green-600 shadow hover:text-green-700 focus:outline-none focus:ring active:text-green-500 sm:w-auto"
          href="https://instagram.com/casval.conference"
        >
          Takip Et!
        </a>
      </div>
    </div>
  </div>
</section>
  );
}

export default App;
